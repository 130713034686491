import { createStore, select, withProps } from '@ngneat/elf';
import {
  withEntities,
  selectAllEntities,
  addEntities,
  deleteEntities,
  withActiveId,
  selectActiveEntity,
  setActiveId,
  getEntity,
  updateEntities,
  selectEntitiesCount,
  getAllEntities,
  deleteAllEntities,
} from '@ngneat/elf-entities';
import { Injectable } from '@angular/core';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { StoreManagementService } from '../store-management/store-management.service';
import { Sync, SyncItemStatus } from './sync.interface';

const name = 'sync';

const initialState = {
  isAirplaneModeEnabled: false,
};

// we use the url as id as urls contain checkupID.
// the addSync method prevents from having two entities with same URL
const store = createStore(
  { name },
  withProps(initialState),
  withEntities<Sync, 'url'>({ idKey: 'url' }),
  withActiveId()
);

export let persist;

@Injectable({ providedIn: 'root' })
export class SyncRepository {
  activeSync$ = store.pipe(selectActiveEntity());
  sync$ = store.pipe(selectAllEntities());
  count$ = store.pipe(selectEntitiesCount());
  isAirplaneModeEnabled$ = store.pipe(select((state) => state.isAirplaneModeEnabled));

  constructor(private storeManagementService: StoreManagementService) {
    persist = persistState(store, {
      key: name,
      storage: localStorageStrategy,
    });

    this.storeManagementService.addToPersistList(name);
  }

  /**
   * When adding to the store, the order is important
   * When there is already an entitiy with the same URL, we want to remove the old entry
   * and add a new one to update its order
   * @param sync
   */
  addSync(sync: Sync) {
    const savedItem = store.query(getEntity(sync.url));

    if (savedItem) {
      this.deleteSync(sync.url);
    }

    // merge sync items
    // this is important as some requests are the same with different payloads
    // eg.: "General Tab" and "Ethnicity Input" on Spirometry
    const item = savedItem ? { ...savedItem, ...sync } : sync;
    store.update(addEntities(item));
  }

  deleteSync(url: Sync['url']) {
    store.update(deleteEntities(url));
  }

  setActiveId(url: Sync['url']) {
    store.update(setActiveId(url));
  }

  updateEntityStatus(url: Sync['url'], status: SyncItemStatus) {
    store.update(updateEntities(url, (entity) => ({ ...entity, status })));
  }

  getSyncItems(): Sync[] {
    return store.query(getAllEntities());
  }

  /**
   * A Staff can only sync items that were added to the sync list by them
   * We use the staffId to get the next available Sync Item for that staff member
   * @param staffId
   * @returns
   */
  getNextSyncItem(staffId: string | number) {
    const entities = store.query(getAllEntities());
    const nextPossibleSync = entities.find((item) => item.staffId === staffId);
    return nextPossibleSync;
  }

  getAirplaneModeEnabled() {
    return store.state.isAirplaneModeEnabled;
  }

  setAirplaneModeEnabled(isEnabled: boolean) {
    store.update((state) => ({ ...state, isAirplaneModeEnabled: isEnabled }));
  }

  reset() {
    store.reset();
  }

  discardRequests() {
    store.update(deleteAllEntities());
  }
}
