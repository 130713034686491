import { Injectable } from '@angular/core';
import { createStore, select, setProps, withProps } from '@ngneat/elf';
import {
  getActiveEntity,
  getAllEntities,
  getAllEntitiesApply,
  getEntity,
  resetActiveId,
  selectActiveEntity,
  selectAllEntities,
  selectAllEntitiesApply,
  setActiveId,
  setEntities,
  updateEntities,
  updateEntitiesByPredicate,
  withActiveId,
  withEntities,
} from '@ngneat/elf-entities';

import { localStorageStrategy, persistState, sessionStorageStrategy } from '@ngneat/elf-persist-state';
import { map, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { StationsRepository } from '../../stations/state/stations.repository';
import { CheckupReading, CheckupResponse, Observation, UserdataBody } from './checkups.interface';
import { CheckupsRepository } from './checkups.repository';

import {
  CheckupSectionState,
  DeviceBiomarker,
  LabDevice,
  LabDeviceStatus,
  LabDeviceWithReading,
  LabDeviceWithReadingTab,
} from './lab-device.interface';

const LAST_STATION_DEVICE_HANDLE = environment.config?.lastStationDeviceHandle || 'finish-checkup';
const GENERAL_DEVICE_HANDLE = environment.config?.generalDeviceHandle || 'wellabe-userdata';

const initialState = {
  isLastStation: false,
};

const store = createStore(
  { name: 'devices' },
  withProps(initialState),
  withEntities<LabDeviceWithReading, 'lab_device_id'>({ initialValue: [], idKey: 'lab_device_id' }),
  withActiveId()
);

export let persist;

@Injectable({ providedIn: 'root' })
export class LabDeviceRepository {
  devices$ = store.pipe(selectAllEntities());
  attentionDevices$ = store.pipe(selectAllEntitiesApply({ filterEntity: (device) => device.attention }));
  activeDevice$ = store.pipe(selectActiveEntity());
  isLastStation$ = store.pipe(select((state) => state.isLastStation));

  activeDeviceTabs$ = store.pipe(
    selectActiveEntity(),
    map((res) => res?.tabs)
  );

  /**
   *  Checking if it should be possible to finish the checkup.
   *  Should return true if:
   *    - There aren no devices running
   *    - All devices are either 'green' or 'yellow'
   */
  checkupFinishEnabled$: Observable<boolean> = store.pipe(
    selectAllEntities(),
    map((devices) => {
      let isAvailable = true;

      devices.forEach((device) => {
        if (device.timer) {
          isAvailable = false;
          return;
        }
        if (
          device.status !== LabDeviceStatus.neutral &&
          device.status !== LabDeviceStatus.success &&
          device.status !== LabDeviceStatus.warning &&
          device.status !== null
        ) {
          isAvailable = false;
          return;
        }
      });

      return isAvailable;
    })
  );

  private readonly USER_FEMALE_INDEX = 2;

  constructor(private stationsRepository: StationsRepository, private checkupsRepository: CheckupsRepository) {
    const strategy = environment.production ? sessionStorageStrategy : localStorageStrategy;

    persist = persistState(store, {
      key: 'devices',
      storage: strategy,
    });
  }

  setActiveDevice(deviceId: LabDeviceWithReading['lab_device_id']) {
    const activeEntity = store.query(getActiveEntity());
    store.update(updateEntities(activeEntity.lab_device_id, { selected: false }));
    store.update(updateEntities(deviceId, { selected: true }));
    store.update(setActiveId(deviceId));
  }

  resetActiveDevice() {
    store.update(resetActiveId());
  }

  isAnyDeviceLoading(): boolean {
    const loadingDevices = store.query(
      getAllEntitiesApply({ filterEntity: (e) => e.status === LabDeviceStatus.loading })
    );

    return loadingDevices && loadingDevices.length > 0;
  }

  isAnyDeviceOnWarning(): boolean {
    return (
      store.query(getAllEntitiesApply({ filterEntity: (entity) => entity?.status === LabDeviceStatus.warning }))
        ?.length > 0
    );
  }

  /**
   * Check if user has pacemaker or if the user is female and is pregnant.
   * If any of these conditions are true the observable should return true.
   * This observable is used to determine either to show or hide the app-device-warning on checkup.page.
   * This condition is only checked in the frontend.
   * @param checkupId
   * @returns an observable with a boolean value based on checkup repository data
   */
  selectAttentionWarning(checkupId: string | number): Observable<boolean> {
    return this.checkupsRepository.selectCheckup(checkupId).pipe(
      map((checkup) => {
        const isUserPregnant = checkup?.user?.sex === this.USER_FEMALE_INDEX && checkup?.is_pregnant;

        return isUserPregnant || checkup?.has_pacemaker;
      })
    );
  }

  /**
   * Check if the user can leave the device on page
   * @param deviceId
   * @returns
   */
  canLeaveDevice(deviceId: LabDeviceWithReading['lab_device_id']): boolean {
    const device = this.getDevice(deviceId);
    if (device.handle === GENERAL_DEVICE_HANDLE) {
      return true;
    }
    const status = device?.status;
    if (!status || status === LabDeviceStatus.neutral || status === LabDeviceStatus.success) {
      return true;
    }

    const invalidResults = this.getInvalidBiomarkers(deviceId);
    return invalidResults?.length <= 0;
  }

  /*
      Given a set of previous checkup readings, update our devices in the store.
      If any device has a non-nullable reading value, set all devices to "manual input"
      mode.
      Should check the "valid status" of all devices and update them accordingly
  */
  fillDeviceReadings(readings: CheckupReading[]): void {
    const validReadings = readings.filter((reading) => reading.value !== null);
    if (validReadings?.length === 0) {
      return;
    }

    const devices = store.query(getAllEntities());
    const updatedDevices = devices.map((device) => {
      let deviceHasPreviousValue = false;

      const updatedTabs: LabDeviceWithReadingTab[] = device.tabs.map((tab) => {
        const updatedBiomarkers: DeviceBiomarker[] = tab.biomarkers.map((biomarker) => {
          const reading = readings.find((r) => r.biomarker_id === biomarker.biomarker_id);
          /*
            if a device has values stored on the API, even if nullabe,
            we consider the device valid
          */
          if (reading) {
            deviceHasPreviousValue = true;
            return { ...biomarker, value: reading.value };
          } else {
            return biomarker;
          }
        });

        return {
          ...tab,
          biomarkers: updatedBiomarkers,
        };
      });

      return {
        ...device,
        state: CheckupSectionState.Inputs,
        status: deviceHasPreviousValue ? LabDeviceStatus.success : LabDeviceStatus.neutral,
        tabs: updatedTabs,
      };
    });

    store.update(setEntities(updatedDevices));
  }

  setupSingleDeviceData(deviceId: LabDeviceWithReading['lab_device_id'], readings: CheckupReading[]): void {
    /* Reset the state of the device before setting data */
    const device = store.query(getEntity(deviceId));
    if (!device) {
      return;
    }

    const updatedTabs = device.tabs.map((tab) => {
      const updatedBiomarkers = tab.biomarkers.map((biomarker) => {
        const value = readings.find((r) => r.biomarker_id === biomarker.biomarker_id)?.value ?? null;
        return { ...biomarker, value };
      });

      return { ...tab, biomarkers: updatedBiomarkers };
    });

    store.update(updateEntities(deviceId, { ...device, tabs: updatedTabs }));
  }

  getDevice(deviceId: LabDeviceWithReading['lab_device_id']) {
    return store.query(getEntity(deviceId));
  }

  /**
   *  Return an array of devices that have a 'warning' status
   */
  getPendingDevices() {
    return store.query(
      getAllEntitiesApply({
        filterEntity: (e) => e.status === LabDeviceStatus.warning,
      })
    );
  }

  /**
   * @param deviceHandle
   * @returns an array of biomarkers from a given device handle
   */
  getAllBiomarkers(deviceHandle: LabDeviceWithReading['handle']): DeviceBiomarker[] {
    const deviceBiomarkers = store.query(
      getAllEntitiesApply({
        mapEntity: (e) => {
          const biomarkers = [];
          for (const tab of e.tabs) {
            biomarkers.push(...tab.biomarkers);
          }
          return biomarkers;
        },
        filterEntity: (e) => e.handle === deviceHandle,
      })
    );

    return deviceBiomarkers.length > 0 ? deviceBiomarkers[0] : [];
  }

  /**
   * @param deviceId
   * @returns a flattened array of biomarkers ready to be submitted to the API
   */
  getDeviceReadings(deviceId: LabDeviceWithReading['lab_device_id']): CheckupReading[] {
    const device = this.getDevice(deviceId);

    const deviceBiomarkers = this.getAllBiomarkers(device.handle);
    const readings = deviceBiomarkers.map((b) => ({ biomarker_id: b.biomarker_id, value: b.value }));
    return readings;
  }

  /**
   * @param deviceId
   * @returns an array of invalid biomarkers from a given device id
   */
  getInvalidBiomarkers(deviceId: LabDeviceWithReading['lab_device_id']): DeviceBiomarker[] {
    const device = store.query(getEntity(deviceId));
    if (!device) {
      return [];
    }

    const invalidBiomarkers = [];
    device.tabs.forEach((tab) => {
      tab.biomarkers.forEach((biomarker) => {
        if (
          biomarker.value === null ||
          (typeof biomarker.value === 'number' && biomarker.value < biomarker.min_value) ||
          (typeof biomarker.value === 'number' && biomarker.value > biomarker.max_value)
        ) {
          invalidBiomarkers.push(biomarker);
        }
      });
    });

    return invalidBiomarkers;
  }

  getInvalidDevices(): { device: LabDeviceWithReading; fields: DeviceBiomarker[] }[] {
    const invalidDevices = [];

    const devices = store.query(getAllEntities());
    devices
      .filter((device) => device.status === LabDeviceStatus.warning)
      .forEach((device) => {
        const invalidResults = this.getInvalidBiomarkers(device.lab_device_id);
        if (invalidResults?.length > 0) {
          invalidDevices.push({ device, fields: invalidResults });
        }
      });

    return invalidDevices;
  }

  updateDeviceStatus(deviceId: LabDeviceWithReading['lab_device_id'], status: LabDeviceStatus) {
    store.update(updateEntities(deviceId, { status }));
  }

  updateDeviceState(deviceId: LabDeviceWithReading['lab_device_id'], state: CheckupSectionState) {
    store.update(updateEntities(deviceId, { state }));
  }

  updateDeviceTimer(deviceId: LabDeviceWithReading['lab_device_id'], timer: string) {
    const entity = store.query(getEntity(deviceId));
    store.update(updateEntities(entity.lab_device_id, { timer }));
  }

  /*
    This function has the objective of updating a device with new readings.
    This is done by searching each tab, then each biormarker to see if it has a new reading
  */
  updateDeviceReadings(deviceId: LabDeviceWithReading['lab_device_id'], readings: CheckupReading[]) {
    const entity = { ...store.query(getEntity(deviceId)) };
    const updatedTabs = [];
    entity.tabs.forEach((tab) => {
      const { biomarkers } = tab;
      const updatedBiomarkers = [];

      biomarkers.forEach((biomarker) => {
        const reading = readings.find((r) => r.biomarker_id === biomarker.biomarker_id);
        if (reading) {
          updatedBiomarkers.push({ ...biomarker, value: reading.value });
        } else {
          updatedBiomarkers.push(biomarker);
        }
      });

      updatedTabs.push({ ...tab, biomarkers: updatedBiomarkers });
    });

    store.update(updateEntities(deviceId, { tabs: updatedTabs }));
  }

  updateGeneralTab(checkupResponse: CheckupResponse, observationResponse: Observation) {
    const generalBiomarkers = [
      {
        biomarker_id: 'Nickname',
        lab_name: 'Nickname',
        value: checkupResponse?.user?.name,
      },
      {
        biomarker_id: 'Birth Year',
        lab_name: 'Birth Year',
        value: checkupResponse?.user?.birth_year,
      },
      {
        biomarker_id: 'Height',
        lab_name: 'Height',
        value: checkupResponse?.user?.height,
      },
      {
        biomarker_id: 'Sex',
        lab_name: 'Sex',
        value: checkupResponse?.user?.sex,
      },
      {
        biomarker_id: 'Smoking Status',
        lab_name: 'Smoking Status',
        value: checkupResponse?.smoking_status,
      },
      {
        biomarker_id: 'Activity Level',
        lab_name: 'Activity Level',
      },
      {
        biomarker_id: 'Last Sport',
        lab_name: 'Last Sport',
        value: checkupResponse?.hours_last_exercise,
      },
      {
        biomarker_id: 'Intense Activity',
        lab_name: 'Intense Activity',
        value: checkupResponse?.recent_intense_exercise,
      },
      {
        biomarker_id: 'Last Meal',
        lab_name: 'Last Meal',
        value: checkupResponse?.hours_last_meal,
      },
      {
        biomarker_id: 'Pacemaker',
        lab_name: 'Pacemaker',
        value: checkupResponse?.has_pacemaker,
      },
      {
        biomarker_id: 'Diabetes',
        lab_name: 'Diabetes',
        value: checkupResponse?.has_diabetes,
      },
      {
        biomarker_id: 'Treatment',
        lab_name: 'Treatment',
        value: checkupResponse?.in_medical_treatment,
      },
      {
        biomarker_id: 'Pregnant',
        lab_name: 'Pregnant',
        value: checkupResponse?.is_pregnant,
      },
    ];

    let checkupFlag = '';
    if (observationResponse?.quality_related) {
      checkupFlag = 'quality_related';
    } else if (observationResponse?.special_case) {
      checkupFlag = 'special_case';
    }

    const tabs = [
      {
        name: 'General',
        biomarkers: generalBiomarkers,
      },
      {
        name: 'Observations',
        biomarkers: [
          {
            biomarker_id: 'Observations',
            lab_name: 'Observations',
            value: observationResponse?.text || '',
          },
          {
            biomarker_id: 'Flag',
            lab_name: 'Flag',
            value: checkupFlag,
          },
        ],
      },
    ];

    store.update(
      updateEntitiesByPredicate(
        ({ handle }) => handle === GENERAL_DEVICE_HANDLE,
        (entity) => {
          return { ...entity, tabs } as LabDeviceWithReading;
        }
      )
    );
  }

  getUserDataPayload(checkupId: CheckupResponse['checkup_id']): UserdataBody {
    const generalDevice = this.getGeneralDevice();
    if (!generalDevice) {
      return null;
    }

    const fieldsMap = {};
    generalDevice.tabs[0]?.biomarkers?.map((biomarker) => {
      fieldsMap[biomarker.biomarker_id] = biomarker.value;
    });

    /* eslint-disable @typescript-eslint/dot-notation */
    const reqData = {
      name: fieldsMap['Nickname'],
      birth_year: fieldsMap['Birth Year'],
      height: fieldsMap['Height'],
      sex: fieldsMap['Sex'],
      smoking_status: fieldsMap['Smoking Status'],
      hours_last_exercise: fieldsMap['Last Sport'],
      activity_level: fieldsMap['Activity Level'],
      recent_intense_exercise: fieldsMap['Intense Activity'],
      hours_last_meal: fieldsMap['Last Meal'],
      has_pacemaker: fieldsMap['Pacemaker'],
      has_diabetes: fieldsMap['Diabetes'],
      in_medical_treatment: fieldsMap['Treatment'],
      is_pregnant: fieldsMap['Pregnant'],
      checkup_id: checkupId,
    };
    /* eslint-enable @typescript-eslint/dot-notation*/

    return reqData;
  }

  getObservationsPayload() {
    const generalDevice = this.getGeneralDevice();
    const observationsText = generalDevice.tabs[1].biomarkers.find(
      (b) => b.biomarker_id === 'Observations'
    )?.value;
    const observationsFlag = generalDevice.tabs[1].biomarkers.find((b) => b.biomarker_id === 'Flag')?.value;

    const observationReqObj = {
      text: observationsText as string,
      quality_related: observationsFlag === 'quality_related',
      special_case: observationsFlag === 'special_case',
    };

    return observationReqObj;
  }

  getGeneralDevice() {
    const generalDevice = store.query(
      getAllEntitiesApply({
        filterEntity: (e) => e.handle === GENERAL_DEVICE_HANDLE,
      })
    );

    return generalDevice?.length > 0 ? generalDevice[0] : null;
  }

  /*
    Check the current station and update the devices on the store to a "clean slate"
    for our checkup page.
  */
  updateCurrentDevices() {
    const devices: LabDevice[] = this.stationsRepository.getActiveStation()?.lab_devices;
    if (devices.length <= 0) {
      store.update(setEntities([]));
      return;
    }

    const isLastStation = devices.findIndex((device) => device.handle === LAST_STATION_DEVICE_HANDLE) >= 0;
    store.update(setProps({ isLastStation }));

    const devicesWithReadings: LabDeviceWithReading[] = devices
      .filter((device) => device.handle != LAST_STATION_DEVICE_HANDLE) // removes last station device, as we don't show it on the devices list on the sidebar
      .map((device) => {
        const tabs: LabDeviceWithReadingTab[] = device.tabs.map((tab) => {
          // resets the value of all biomarkers in all tabs of all devices
          const biomarkers: DeviceBiomarker[] = tab.biomarkers.map((biomarker) => {
            return {
              ...biomarker,
              value: null,
            };
          });

          return {
            ...tab,
            biomarkers,
          };
        });

        // business logic: manual measurement devices skips the screen with "Start Button"
        const initialDeviceState = device.manual_measurement
          ? CheckupSectionState.Inputs
          : CheckupSectionState.Introduction;

        const deviceWithReading: LabDeviceWithReading = {
          ...device,
          selected: device.order === 0,
          status: LabDeviceStatus.neutral,
          manual_measurement: device.manual_measurement,
          state: initialDeviceState,
          duration: device.duration,
          timer: null,
          tabs,
        };

        return deviceWithReading;
      });

    const activeId = devicesWithReadings[0]?.lab_device_id;
    store.update(setEntities(devicesWithReadings), setActiveId(activeId));
  }
}
